body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.styles_app__2-7Lo {
  width: 100vw;
  height: 100vh;
}

h3 {
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}

p {
  margin-block-start: 0;
  margin-block-end: 0;
}

.styles_infoBoxContainer__3jt6z {
  display: flex;
  flex-flow: column nowrap;
  padding: 1em;
  font-size: 1.2em;
  background-color: #fff;
}

.styles_infoBoxContainer__3jt6z span {
  font-weight: bold;
}
.styles_mapContainer__3cATe {
  height: 100vh;
}

.styles_container__1xVMg {
    display: flex;
    flex-flow: row wrap;
}

.styles_mapContainer__2n1rV {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    text-align: center;
    padding: 2vh 1%;
    height: 46vh;
    width: 48%;
}

.styles_title__trSn8 {
    margin-bottom: 0.5vh;
}

.styles_titleLabel__2JrbY {
    font-weight: bold;
}

.styles_titleValue__31B08 {
    font-weight: bold;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.infoBox {
  width: 200px;
}

