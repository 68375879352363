.app {
  width: 100vw;
  height: 100vh;
}

h3 {
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}

p {
  margin-block-start: 0;
  margin-block-end: 0;
}

.infoBoxContainer {
  display: flex;
  flex-flow: column nowrap;
  padding: 1em;
  font-size: 1.2em;
  background-color: #fff;
}

.infoBoxContainer span {
  font-weight: bold;
}