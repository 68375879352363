.container {
    display: flex;
    flex-flow: row wrap;
}

.mapContainer {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    text-align: center;
    padding: 2vh 1%;
    height: 46vh;
    width: 48%;
}

.title {
    margin-bottom: 0.5vh;
}

.titleLabel {
    font-weight: bold;
}

.titleValue {
    font-weight: bold;
}
